@import "../var";
@import "var_public";

@import "../base";
@import "../loader";

header {

    .header-wrapper {

        @media (max-width: ($site-width + (2 * $header-height-max)) - 1px) {
            padding-left: $site-width-pad-x;
            padding-right: $site-width-pad-x;
        }
    }

    .nav-content .wrapper {

        @media (min-width: $s-nav-burger-limit) {
            justify-content: flex-end;
        }

        .header-menu-secondary .button {

            &.button2{

                @media (min-width: $s-nav-burger-limit) {
                    margin-left: 15px;
                }
            }
        }
    }
}

#prefooter {

    //.site-content {
    //    background-color: $bg-white;
    //}

    .prefooter-content {
        padding: {
            top: $section-pad-y-min;
            bottom: $section-pad-y-min;
        }

        @media (min-width: $s-mobile) {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: {
                top: $section-pad-y-max;
                bottom: $section-pad-y-max;
            }
        }

        .title {

            @media (min-width: $s-mobile) {
                order: 1;
                margin: {
                    bottom: 0;
                    right: 20px;
                }
            }
        }

        ul.links-list {
            margin: 20px 0;
            padding: 0;
            list-style: none;

            @media (min-width: $s-mobile-sm) {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
            }

            @media (min-width: $s-mobile) {
                order: 3;
                width: 100%;
                margin-top: 30px;
            }

            & > li {
                margin-bottom: 10px;

                @media (min-width: $s-mobile-sm) {
                    width: calc((100% - 20px) / 2);
                    margin-right: 20px;

                    &:nth-of-type(2n) {
                        margin-right: 0;
                    }
                }

                @media (min-width: $s-mobile) {
                    width: calc((100% - 40px) / 3);

                    &:nth-of-type(2n) {
                        margin-right: 20px;
                    }

                    &:nth-of-type(3n) {
                        margin-right: 0;
                    }
                }

                @media (min-width: $s-desktop) {
                    width: calc((100% - 60px) / 4);

                    &:nth-of-type(3n) {
                        margin-right: 20px;
                    }

                    &:nth-of-type(4n) {
                        margin-right: 0;
                    }
                }

                a {
                    text-decoration: none;

                    .no-touchevents &:hover {
                        //text-decoration: underline;
                    }
                }
            }
        }

        .link {

            @media (min-width: $s-mobile) {
                order: 2;
            }
        }
    }
}

footer {

    .footer-cols {

        .logo a {
            height: 20px;
            margin-bottom: 10px;
        }

        nav.menu > ul > li.col1 {
            margin-bottom: 0;

            .infos {

                & > div:not(:last-child) {
                    margin-bottom: 0;

                    @media (min-width: $s-tablet) {
                        margin-bottom: 20px;
                    }

                    &.hours {
                        width: 100%;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

.bg-dark,
.section.bg-dark,
.content-block.quote-content .text {
    background: $bg-dark url('../../images/pattern_secondary.jpg');
}

form,
.form {

    .form-control {

        .no-touchevents &:not(:focus):hover {
            border-color: $form-field-border-color;
        }
    }
}

section {

    &:first-of-type {
        padding: $section-pad-y-min 0;

        @media (min-width: $s-mobile) {
            padding: 2*$section-pad-y-min 0;
        }
    }

    &:last-of-type:not(.bottom) {
        padding-bottom: 0;
    }

    &.banner {

        .banner-content {
        }
    }
}


$columnsBreakpoint: $s-tablet;
.columns-container {

    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: ($columnsBreakpoint)) {
        flex-direction: row;
        justify-content: center;
    }

    .column-left {

        @media (min-width: ($columnsBreakpoint)) {
            width: calc(100% - 340px);
        }
    }

    .column-right {

        @media (min-width: ($columnsBreakpoint)) {
            width: 290px;
            margin-left: 50px;
        }

        .sticky {

            @media (min-width: ($columnsBreakpoint)) {
                position: sticky;
                top: $header-height-max + $section-pad-y-max;
                padding-top: 50px;
            }
        }
    }
}


// City search form
.city-search-form {

    label {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0;

        & > i {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            font-size: 15px;
            line-height: 20px;
            color: $c-text;
        }

        input.form-control {
            flex-grow: 1;
            padding: {
                top: 17.5px;
                bottom: 17.5px;
                left: 50px;
            }
            font-size: 16px;
            line-height: 20px;

            &::placeholder {
                font-size: $f-size-base;
            }
        }

        .select2-container {
            flex-grow: 1;
            //height: 25px;
            //line-height: 25px;
            //background: none;
            //border: none;

            .select2-selection {
                padding-left: 60px;
                //padding: 0 0 0 50px;
                //background: none;
                //border: none;

                .select2-selection__arrow {
                    display: none;
                }
            }

            .select2-dropdown {
                padding-left: 60px;
            }
        }
    }
}


// Specific for page home
body.page.home {

    &:not(.scrolling) header {
        background-color: transparent;
        box-shadow: none;
    }

    main {
        padding-top: 0;
        //overflow-x: hidden;
    }

    section.banner {
        padding: {
            top: 0;
            bottom: 0;
        }

        .banner-content {
            display: block;
            position: relative;
            padding-top: $header-height-min;

            @media (min-width: $s-mobile) {
                padding: {
                    top: 150px;
                    right: 250px;
                }
            }

            @media (min-width: $s-tablet) {
                padding-right: calc(100% - 550px - $site-width-pad-x);
            }

            @media (min-width: $site-width-no-padding) {
                padding: {
                    left: 100px;
                    right: calc($site-width - 650px);
                }
            }
        }

        .bubble {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            //width: 800px;
            width: 100%;
            height: 750px;
            background: {
                image: url("../../images/bubble-blue-big.svg");
                //position: bottom right;
                position-x: -400px;
                position-y: bottom;
                repeat: no-repeat;
            }
            pointer-events: none;

            @media (min-width: $s-tablet) {
                //width: 800px;
                height: 800px;
            }

            @media (min-width: $s-desktop) {
                left: auto;
                right: 400px;
                //width: 2000px;
                width: calc(100vw - 400px);
                background-position: bottom right;
            }
        }

        .avatar {
            height: 240px;
            margin: 0;
            padding: 20px 0;

            @media (min-width: $s-mobile) {
                position: absolute;
                bottom: -$section-pad-y-max;
                right: 20px;
                width: 200px;
                height: auto;
                padding: 0;
                //display: flex;
                //align-items: flex-end;
            }

            @media (min-width: $s-tablet) {
                //width: 250px;
                width: auto;
                height: 390px;
                right: 60px;
                bottom: -2 * $section-pad-y-max;
            }

            img {
                display: block;
                max-height: 100%;

                @media (min-width: $s-mobile) {
                    margin: 0 auto;
                }
            }
        }

        .text {
            margin-bottom: 20px;
        }

        .search-form {
            //margin-bottom: 20px;
            max-width: 500px;
            margin: 0;
        }

        .search-buttons {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 15px;
            margin: 20px 0 0;

            a.button.button-small {

                @media (max-width: $s-mobile - 1px) {
                    padding: {
                        left: 15px;
                        right: 15px;
                    }

                    & > i {
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    section.intro {

        @media (min-width: $s-tablet) {
            padding-top: 2 * $section-pad-y-max;
        }

        .intro-content {

            @media (min-width: $s-mobile) {
                display: flex;
                justify-content: space-between;
            }
        }

        .intro-block {
            margin-top: 20px;
            padding: 30px;
            background-color: $bg-white;

            @media (min-width: $s-mobile) {
                width: calc((100% - 40px) / 3);
                margin-top: 0;
            }

            @media (min-width: $s-desktop) {
                width: calc((100% - 100px) / 3);
                padding: 40px 50px;
            }

            &:first-of-type {
                margin-top: 0;
            }

            .title {
                font-size: 18px;
                text-align: center;

                @media (min-width: $s-mobile) {
                    font-size: 20px;
                }
            }

            .picture {
                margin-bottom: 20px;
            }
        }
    }

    section.content-blocks {
        //padding-bottom: 0;

        //.adv-content {
        //    position: relative;
        //
        //    &::before {
        //
        //        @media (min-width: $s-mobile) {
        //            content: '';
        //            position: absolute;
        //            z-index: -1;
        //            top: 0;
        //            width: 50%;
        //            height: 100%;
        //            background: {
        //                size: auto 100%;
        //                repeat: no-repeat;
        //            }
        //            pointer-events: none;
        //        }
        //
        //        @media (min-width: $s-desktop) {
        //            top: -10%;
        //            width: calc(49vw - 150px);
        //            height: 120%;
        //            background-size: contain;
        //        }
        //    }
        //
        //    &:nth-child(2n) {
        //
        //        &::before {
        //
        //            @media (min-width: $s-mobile) {
        //                left: 50%;
        //                background: {
        //                    image: url("../../images/bubble-blue.svg");
        //                    position: left;
        //                }
        //            }
        //
        //            @media (min-width: $s-desktop) {
        //                left: calc(50% + 150px);
        //            }
        //        }
        //    }
        //
        //    &:nth-child(2n+1) {
        //
        //        &::before {
        //
        //            @media (min-width: $s-mobile) {
        //                right: 50%;
        //                background: {
        //                    image: url("../../images/bubble-orange.svg");
        //                    position: right;
        //                }
        //            }
        //
        //            @media (min-width: $s-desktop) {
        //                right: calc(50% + 150px);
        //            }
        //        }
        //    }
        //}
    }

    section.families {

        .families-content {

            @media (min-width: $s-mobile) {
                display: flex;
                align-items: stretch;
            }

            .infos {
                padding: 25px;

                @media (min-width: $s-mobile) {
                    flex: 1 1 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                @media (min-width: $s-tablet) {
                    padding: 50px;
                }

                @media (min-width: $s-desktop) {
                    padding: 50px 100px;
                }
            }

            .picture {
                margin-top: 20px;

                @media (min-width: $s-mobile) {
                    flex: 1 1 50%;
                    margin-top: 0;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        & + section.testimonies {
            padding-top: 0;
        }
    }

    section.testimonies {
    }

    section.support {

        .support-content {
            padding: {
                top: 25px;
                bottom: 25px;
            }

            @media (min-width: $s-mobile) {
                display: flex;
                align-items: center;
            }

            @media (min-width: $s-tablet) {
                padding: 40px 50px;
            }

            @media (min-width: $s-desktop) {
                padding: 80px 100px;
            }

            .infos {

                @media (min-width: $s-mobile) {
                    padding-right: 20px;
                }

                @media (min-width: $s-tablet) {
                    padding-right: 40px;
                }

                @media (min-width: $s-desktop) {
                    padding-right: 80px;
                }
            }

            .picture {
                margin-top: 20px;

                @media (min-width: $s-mobile) {
                    margin-top: 0;
                }
            }
        }
    }

    section.press {
        text-align: center;

        .press-content {
            padding: {
                top: $section-pad-y-min;
                bottom: $section-pad-y-min;
            }

            @media (min-width: $s-mobile) {
                padding: {
                    top: 60px;
                    bottom: 60px;
                }
            }

            .media-list {

                @media (min-width: ($s-mobile)) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin: 0 -10px;
                }

                .media {
                    break-inside: avoid;
                    page-break-inside: avoid;
                    margin-top: 20px;
                    padding: 25px;
                    background-color: $bg-white;

                    @media (min-width: $s-mobile) {
                        width: calc((100% - 60px) / 3);
                        margin: {
                            left: 10px;
                            right: 10px;
                        }
                    }

                    @media (min-width: $s-tablet) {
                        width: calc((100% - 80px) / 4);
                    }
                }
            }
        }
    }

    section.blog {

        .title {
            margin-bottom: 30px;

            @media (min-width: ($s-mobile)) {
                margin-bottom: 50px;
            }
        }

        .blogs-list {
            margin-bottom: 30px;

            @media (min-width: ($s-mobile)) {
                margin-bottom: 50px;
            }
        }

        .link {
            text-align: center;
        }

        & + section.faq {
            @media (min-width: $s-tablet) {
                margin-top: $section-pad-y-max;
            }
        }
    }

    section.faq {
        padding: {
            top: 0;
            bottom: 0;
        }

        .faq-content {
            padding: {
                top: $section-pad-y-min;
                bottom: $section-pad-y-min;
            }

            @media (min-width: ($s-mobile)) {
                padding: {
                    top: $section-pad-y-max;
                    bottom: $section-pad-y-max;
                }
            }

            .columns {
                display: flex;
                flex-direction: column;

                @media (min-width: ($s-mobile)) {
                    flex-direction: row;
                }
            }

            .infos {
                flex: 1 1 50%;
                margin-bottom: 30px;

                @media (min-width: ($s-mobile)) {
                    padding-right: 40px;
                    margin-bottom: 0;
                }

                @media (min-width: ($s-tablet)) {
                    padding-right: 94px;
                }

                .sticky {
                    position: sticky;
                    padding-bottom: 0;
                    top: $header-height-min + 30px;

                    @media (min-width: $s-nav-burger-limit) {
                        top: $header-height-max + 30px;
                    }
                }

                .title {
                    margin-bottom: 16px;
                }

                .text {
                    margin-bottom: 0;
                }

                .link {
                    margin-top: 28px;
                }
            }

            .list {
                flex: 1 1 50%;
            }
        }
    }
}

// Specific for blog
body.blog {

    #prefooter {
        margin-top: $section-pad-y-min;

        @media (min-width: $s-mobile) {
            margin-top: $section-pad-y-max;
        }
    }

    &.index {

        section.banner {

            .banner-content {
            }
        }
    }

    &.detail {

        section.banner {
            padding: {
                top: 0;
                bottom: 0;
            }
        }
    }
}

// Specific for undertaker results and list
.reviews-rating {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 12px 15px;
    font-weight: bold;
    border-radius: 50px;
    background-color: $c-fourth;

    .rating-stars {
        display: flex;
        align-items: center;
        gap: 2px;
        margin: 0;
        color: $c-third;

        .rating-star {
            position: relative;
            display: flex;
            line-height: 1;

            i.icon-star-full {
                //width: 16px;
                color: $c-third;

                &.on {
                    opacity: 1;
                }

                &.off {
                    opacity: 0.25;
                }

                &.half {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 8px;
                    overflow: hidden;
                    opacity: 1;
                }
            }
        }
    }
}
.reviews-list {

    .review-item {
        display: flex;
        padding: 20px;

        @media (min-width: $s-mobile) {
            padding: 30px;
        }

        .picto,
        .logo {
            min-width: 50px;
            width: 50px;
            height: 50px;
            margin-right: 20px;
            border-radius: 100px;

            @media (min-width: $s-mobile) {
                margin-right: 30px;
            }
        }

        .picto {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font: {
                size: 18px;
                weight: bold;
            }
            line-height: 1;
            color: $c-white;
            background-color: $c-primary;
        }

        .logo {
            background-color: $bg-white;
            overflow: hidden;
        }

        .infos {
            flex-grow: 1;

            .name {
                margin-bottom: 10px;
            }

            .reviews-rating {
                width: max-content;
                padding: 8px 14px;
                margin-bottom: 10px;
                font-size: 12px;
                background-color: #f8e4db;

                .rating-stars {
                    gap: 5px;
                }
            }

            .content {
                margin-bottom: 10px;
                padding-bottom: 0;
                font-size: 14px;

                &.expandable-text[open] a.more {
                    display: none;
                }

                .summary {
                    //@include multilineEllipsis(2);
                    margin-bottom: 0;
                }

                a.more {
                    color: $c-primary-lighter;
                    font-weight: normal;
                    text-decoration: none;
                }
            }
        }
    }
}
.undertakers-list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;

    @media (min-width: $s-mobile) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $site-width) {
        gap: 30px;
    }

    .cross-sell > & {
        @media (min-width: $s-mobile) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
.undertaker-item {
    position: relative;
    padding: 0;
    text-align: left;
    box-shadow: $shadowLarge;

    transition: {
        property: transform, box-shadow;
        duration: $t-duration;
    }
    border-radius: 15px;
    overflow: hidden;
    color: $c-text;

    @media (min-width: $s-mobile) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .main-picture {
        height: 50vw;
        overflow: hidden;

        @media (min-width: $s-mobile) {
            height: 140px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1);
            transition: transform $t-duration * 2;
        }
    }

    .infos {
        display: flex;
        flex-direction: column;
        padding: 50px 20px 20px;
        background-color: $bg-light;

        @media (min-width: $s-mobile) {
            flex-grow: 1;
            padding: 50px 30px 20px;
        }

        @media (min-width: $s-tablet) and (max-width: $s-desktop - 1px) {
            padding: {
                left: 20px;
                right: 20px;
            }
        }

        .logo-and-reviews {
            position: absolute;
            top: calc(50vw - 30px);
            left: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 15px;
            width: calc(100% - 40px);

            @media (min-width: $s-mobile) {
                top: 110px;
                left: 30px;
                width: calc(100% - 60px);
            }

            @media (min-width: $s-tablet) and (max-width: $s-desktop - 1px) {
                left: 20px;
                width: calc(100% - 40px);
            }

            .logo {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                width: 60px;
                height: 60px;
                border: {
                    style: solid;
                    width: 1px;
                    color: transparentize($c-dark, 0.95);
                    radius: 10px;
                }
                background-color: $bg-white;
                overflow: hidden;
            }
        }

        .undertaker-name {
            @include multilineEllipsis(3);
            margin-bottom: 10px;
        }

        .name {
            color: $c-text;
            text-decoration: none;

            .no-touchevents &:hover,
            .no-touchevents &:focus,
            .no-touchevents &:focus-within {
                text-decoration: none;
                color: $c-text;
            }
        }

        .address {
            margin-bottom: 0;

            @media (min-width: $s-mobile) {
                flex-grow: 1;
            }
        }

        .button {
            margin-top: 20px;
        }
    }

    .no-touchevents &:hover,
    .no-touchevents &:focus,
    .no-touchevents &:focus-within {
        box-shadow: $shadowShort;

        .picture.main-picture {
            img {
                transform: scale(1.02);
            }
        }

        .name {
            color: inherit;
        }

        .button {
            @extend .hover;
        }
    }
}
body.undertaker {

    &.index,
    &.detail {

        section.banner {

            .banner-content {
                display: block;
            }
        }
    }

    &.results {

        .columns-container {

            @media (max-width: ($columnsBreakpoint - 1px)) {
                flex-direction: column-reverse;
            }
        }

        section.banner {

            .banner-content {
                display: block;

                @media (min-width: $s-desktop) {
                    padding: {
                        left: 100px;
                        right: 100px;
                    }
                }
            }
        }

        section.results {
            padding-top: 0;

            .content {
                padding: $site-width-pad-x;

                //@media (min-width: $s-mobile) {
                //    padding: 30px;
                //}

                @media (min-width: $s-desktop) {
                    padding: 30px 100px;
                }
            }
        }

        .results-map {
            padding-top: 0;

            .wrapper {

                @media (min-width: ($columnsBreakpoint)) {
                    //height: 70vh;
                    height: calc(100vh - (#{$header-height-max} + 2 * #{$section-pad-y-max}));
                    //max-height: 700px;
                }
            }

            .map-container {
                height: 400px;

                @media (min-width: ($columnsBreakpoint)) {
                    height: 100%;
                }

                .map {

                    .gm-style .gm-style-iw-c .details .button {
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }

        section.search-error {
            padding-top: 0;

            .search-error-content {
                padding: {
                    top: $section-pad-y-min;
                    bottom: $section-pad-y-min;
                }

                @media (min-width: $s-mobile) {
                    display: flex;
                    align-items: center;
                    padding: 50px
                }

                .infos {

                    @media (min-width: $s-mobile) {
                        padding-right: 30px;
                    }
                }

                .link {
                    margin-top: 20px;

                    @media (min-width: $s-mobile) {
                        min-width: max-content;
                        margin-top: 0;
                    }
                }
            }
        }

        footer {
            margin-top: $section-pad-y-min;

            @media (min-width: $s-mobile) {
                margin-top: $section-pad-y-max;
            }
        }
    }

    &.index {

        .city {

            a {
                display: inline-block;
                padding: 5px 0;
                text-decoration: none;
            }
        }

        section.cities {
            padding-top: 0;

            .highlighted-cities {

                &.highlighted-cities-with-pic {
                    margin-bottom: 20px;

                    @media (min-width: $s-mobile) {
                        margin-bottom: 30px;
                    }

                    .cities-list {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        gap: 30px;
                        padding: 30px 0;

                        @media (min-width: $s-mobile) {
                            flex-direction: row;
                            flex-wrap: wrap;
                            align-items: stretch;
                        }

                        @media (min-width: $s-tablet) {
                            flex-wrap: nowrap;
                        }

                        .city {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            max-width: 300px;
                            padding: 30px 0;
                            border-radius: 15px;
                            box-shadow: $btn-box-shadow;
                            background-color: $bg-white;
                            text-align: center;
                            transition: {
                                property: transform, box-shadow;
                                duration: $t-duration;
                            }

                            @media (min-width: $s-mobile) {
                                width: calc(50% - 15px);
                            }

                            @media (min-width: $s-tablet) {
                                width: calc(25% - 15px);
                            }

                            .no-touchevents &:hover,
                            .no-touchevents &:focus,
                            .no-touchevents &:focus-within {
                                box-shadow: $btn-box-shadow-hover;

                                a {
                                    color: $c-text;
                                }
                            }

                            .picture {
                                display: flex;
                                align-items: flex-end;
                                justify-content: center;
                                //width: 180px;
                                //height: 140px;
                                width: 100%;
                                height: 100%;
                                padding: 0 50px;
                                margin-bottom: 20px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }

                            .name {
                                padding: 0 30px;
                            }
                        }
                    }
                }

                &.highlighted-cities-without-pic {

                    .cities-list {

                        @media (min-width: $s-mobile) {
                            column-count: 3;
                            column-gap: 20px;
                        }

                        @media (min-width: $s-tablet) {
                            column-count: 4;
                        }

                        h3 {
                            margin-bottom: 0;
                            font-size: $f-size-base;
                        }
                    }
                }
            }
        }

        section.city-search {
            padding-top: 0;

            .city-search-content {
                padding: {
                    top: $section-pad-y-min;
                    bottom: $section-pad-y-min;
                }

                @media (min-width: $s-mobile) {
                    display: flex;
                    align-items: center;
                    padding: 50px
                }

                .infos {

                    @media (min-width: $s-mobile) {
                        flex: 1;
                        padding-right: 30px;
                    }
                }

                .form-container {
                    margin-top: 20px;

                    @media (min-width: $s-mobile) {
                        flex: 1;
                        //min-width: max-content;
                        margin-top: 0;
                    }
                }
            }
        }

        section.departments {
            padding-top: 0;

            .department {

                .department-name {
                    padding: 10px 30px;
                    font-weight: bold;
                }

                .cities-list {
                    padding: 20px 0;

                    @media (min-width: $s-mobile) {
                        padding: 30px 0;
                    }
                }
            }
        }
    }

    &.detail {

        .column-left {
            margin-bottom: $section-pad-y-min;

            @media (min-width: ($columnsBreakpoint)) {
                width: calc(100% - 440px);
                margin-bottom: 0;
            }

            @media (min-width: $s-desktop) {
                width: calc(100% - 490px);
            }
        }

        .column-right {

            @media (min-width: ($columnsBreakpoint)) {
                width: 390px;
            }

            @media (min-width: $s-desktop) {
                margin-left: 100px;
            }

            .sticky {

                @media (min-width: ($columnsBreakpoint)) {
                    position: sticky;
                    top: $header-height-max;
                    padding-top: 30px /* banner padding-top */ + 15px /* breadcrumb height*/ + 20px /* breadcrumb margin-bottom */;
                }
            }
        }

        section {
            &:not(.banner) {
                padding-top: 0;
            }

            @media (min-width: $s-mobile) {
                padding-bottom: 30px;
            }
        }

        section.banner {
            padding-top: 30px;

            .title {
                margin-bottom: 10px;
            }

            .reviews-rating {
                margin-bottom: 15px;

                .reviews-nb a {

                    .no-touchevents &:hover {
                        color: inherit;
                        text-decoration: underline;
                    }
                }
            }

            .pictures {
                width: 100%;
                margin-bottom: 20px;

                @media (min-width: $s-mobile) {
                    display: flex;
                    gap: 20px;
                    margin-bottom: 30px;
                }

                .picture {

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .main-picture {
                    height: 150px;
                    margin-bottom: 10px;

                    @media (min-width: $s-mobile) {
                        flex-grow: 1;
                        height: 340px;
                        margin-bottom: 0;
                    }
                }

                .other-pictures {
                    display: flex;
                    align-items: flex-start;
                    gap: 10px;

                    @media (min-width: $s-mobile) {
                        flex-direction: column;
                        gap: 20px;
                        width: 100px;
                    }

                    .picture {
                        width: 100px;
                        height: 100px;
                    }

                    .logo {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: auto;
                        background-color: $bg-white;
                        border: {
                            style: solid;
                            width: 1px;
                            color: transparentize($c-text, 0.95);
                        }
                    }
                }
            }

            .back-button {
                margin-top: 30px;
            }

            .undertaker-nav {
                margin: 20px auto 0;

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    a.button-small {
                        padding: {
                            left: 10px;
                            right: 10px;
                        }
                    }
                }
            }

            .begin-config-button {
                margin-top: 20px;

                @media (min-width: ($columnsBreakpoint)) {
                    display: none;
                }

                .button {
                    width: 100%;
                }
            }
        }

        section.about {

            .emphasis {
                margin-top: $section-pad-y-min;

                @media (min-width: $s-mobile) {
                    margin-top: 30px;
                }
            }

            .emphasis-content {
                padding: {
                    top: $section-pad-y-min;
                    bottom: $section-pad-y-min;
                }

                @media (min-width: $s-mobile) {
                    padding: 50px
                }
            }
        }

        section.services {

            .service-offerings {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                .service-offering-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: calc(50% - 10px);
                    padding: 20px;
                    text-align: center;

                    @media (min-width: $s-mobile) {
                        padding: 30px 20px;
                    }

                    @media (min-width: $s-desktop) {
                        width: calc((100% - 40px) / 3);
                    }

                    .picture {
                        height: 100px;
                        margin-bottom: 20px;
                    }

                    .category-name {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        section.location {

            .section-title {
                margin-bottom: 10px;
            }

            iframe {
                width: 100%;
                max-width: 100%;
                height: 250px;

                @media (min-width: $s-mobile) {
                    height: 500px;
                }
            }

            .map-container {
                height: 250px;

                @media (min-width: $s-mobile) {
                    height: 500px;
                }
            }
        }

        section.other-pictures {

            .section-title {
                margin-bottom: 0;
            }

            .pictures {
                column-count: 2;
                column-gap: 20px;

                .picture {
                    break-inside: avoid;
                    //margin-bottom: 20px;
                    border-top: 20px solid transparent;

                    a {

                        .no-touchevents &:hover {
                            img {
                                opacity: 0.5;
                            }
                        }

                        img {
                            transition: opacity $t-duration;
                        }
                    }
                }
            }
        }

        section.reviews {

            .title-and-tag {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @media (min-width: $s-mobile) {
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 20px;
                }

                .section-title {
                    margin-bottom: 5px;
                }

                .reviews-rating {
                    min-width: max-content;
                    margin: 15px 0;

                    @media (min-width: $s-mobile) {
                        margin: 0 0 0 20px;
                    }
                }
            }

            .reviews-list {

                .review-item {

                    &:not(:first-child) {
                        margin-top: 20px;
                    }
                }
            }

            .load-more-wrapper {
                margin-top: 20px;
                text-align: center;
            }
        }

        section.cross-sell {
            padding: 30px 0 40px 0;
        }

        .config {
            padding-bottom: $section-pad-y-min;

            @media (min-width: $columnsBreakpoint) {
                padding-bottom: $section-pad-y-max;
            }

            .content {
                position: relative;

                .wrapper {
                    position: relative;
                    padding: 50px $site-width-pad-x 180px;
                    text-align: center;

                    @media (min-width: ($columnsBreakpoint)) {
                        min-height: calc(100vh - (#{$header-height-max} + 2 * (30px + 15px + 20px))); // 30px = banner padding-top - 15px = breadcrumb height - 20px = breadcrumb margin-bottom
                        padding: 60px 40px 210px;
                    }

                    .button-container,
                    .confirm-text {
                        margin-top: 30px;
                    }

                    .confirm-text {
                        display: none;
                    }

                    .avatar {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 150px;

                        @media (min-width: $s-tablet) {
                            height: 180px;
                        }
                    }
                }

                .config-step-content {
                    min-height: max-content;
                    text-align: center;
                }
            }
        }
    }
}

// Specific for config (and undertaker review)
.config-step-content {
    // Usefull for undertaker detail right column

    .step-head {
        margin: 0 auto 20px;

        @media (min-width: $s-tablet) {
            margin-bottom: 30px;
        }
    }

    ul.answers {
        display: flex;
        flex-direction: column;
        gap: 15px;
        max-width: 100%;
        width: max-content;
        margin: 0 auto;
        padding: 0;
        list-style: none;

        @media (min-width: $s-mobile) {
            max-width: 600px;
            gap: 20px;
            width: auto;
        }

        &.in-row {
            flex-direction: row;
        }

        li {
            display: block;
            width: 100%;

            //&:not(:first-of-type) {
            //    margin-top: 15px;
            //
            //    @media (min-width: $s-mobile) {
            //        margin-top: 20px;
            //    }
            //}

            a {
                width: 100%;

                &.arrow-right {
                    justify-content: space-between;
                    text-align: left;
                }
            }
        }
    }

    .intro-blocks {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        gap: 30px;
        padding-top: 20px;
        padding-bottom: 50px;

        .intro-block {
            background-color: $c-white;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            padding: 30px;
            gap: 20px;
            width: 220px;
            box-shadow: $btn-box-shadow;

            .picture {
                height: 100px;
                width: 160px;

                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }

            .title {
                margin-bottom: 0;
            }
        }
    }
}
body.config {

    header {
        box-shadow: 0 3px 35px 0 rgba(0, 0, 0, 0.05);

        .header-wrapper {
            align-items: center;
            justify-content: center;
        }

        .logo,
        .header-button {
            flex: 1;
            width: auto;
        }

        .logo {
            justify-content: center;
            max-width: inherit;
            height: 50px;
            margin: 0;

            picture {
                height: 100%;

                img {
                    border-radius: 15px;
                }
            }

            .default-logo{
                height: 20px;
            }
        }

        .header-button {

            &.header-right-button {
                text-align: right;
            }

            .button {

                @media (max-width: $s-mobile - 1px) {
                    width: 40px;
                    height: 40px;
                    padding: 0;

                    & > [class*=icon-]:first-of-type {
                        margin-right: 0;
                    }

                    & > [class*=icon-]:last-of-type {
                        margin-left: 0;
                    }

                    & > span {
                        display: none;
                    }
                }
            }
        }
    }

    main {
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        &.bg-light {
            @media (min-width: $s-tablet) {
                background: none;
            }
        }

        & > div.content-limited {
            flex: 1;
            width: 100%;
        }

        section.banner {
            flex: 1;
            padding-top: 0;
            padding-bottom: 0;

            @media (min-width: $s-nav-burger-limit) {
                min-height: calc(100vh - #{$header-height-max});
            }

        }

        #scroll-up {
            display: none;
        }

        #inquiry-details {
            position: fixed;
            z-index: 98;
            bottom: 0;
            left: 0;
            transform: translateY(100%);
            width: 100%;
            max-height: 250px;
            overflow: auto;
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 10px 10px 10px 70px;
            font-size: 0.7em;
            border-top: 5px solid $c-third;
            transition: {
                property: transform;
                duration: 0.05s;
            }

            @media (min-width: $s-tablet) {
                flex-direction: row;
            }

            @media (min-width: $s-desktop-l) {
                flex-direction: column;
                max-height: calc(100% - #{$header-height-max});
                max-width: calc(50% - (#{$site-width-limited} / 2));
                padding: {
                    left: 10px;
                    bottom: 70px;
                }
            }

            &.expanded {
                transform: translateY(0);
            }

            & > div {

                @media (min-width: $s-tablet) {
                    flex: 1;
                }

                table {
                    border: 1px solid #ccc;
                    border-collapse: collapse;

                    tr {
                        th {
                            border: 1px solid #ccc;
                        }
                        td {
                            padding: 2px 5px;
                            border: 1px solid #ccc;
                            vertical-align: top;
                        }
                    }
                }

                h3 {
                    margin-bottom: 10px;
                }

                ul {
                    margin: 0 0 10px;
                    padding-left: 20px;
                }
            }
        }

        #inquiry-details-button {
            position: fixed;
            z-index: 99;
            bottom: 40px;
            left: 10px;
        }

        .config-wrapper {
            display: grid;
            grid-template-columns: 1fr auto;
        }

        .config-sidebar-overlay {
            position: fixed;
            z-index: 200; // over header
            background-color: transparentize(#000, 0.8);
            opacity: 0;
            transition: {
                property: opacity;
                duration: $t-duration;
                timing-function: ease-out;
            }

            &.visible {
                opacity: 1;
                pointer-events: auto;
            }
        }


        $s-docked-sidebar: $s-mobile;

        .help-sidebar-overlay {
            position: fixed;
            z-index: 200; // over header
            background-color: transparentize(#000, 0.8);
            opacity: 0;
            transition: {
                property: opacity;
                duration: $t-duration;
                timing-function: ease-out;
            }

            &.visible {
                opacity: 1;
                pointer-events: auto;

                @media (min-width: $s-docked-sidebar) {
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }

        .help-sidebar {
            $closedHeight: 110px;
            $openedHeight: 75vh;

            position: fixed;
            z-index: 201;
            bottom: 0px;
            top: initial;
            background-color: $c-light;
            border-radius: 30px 30px 0 0;
            box-shadow: 0 -3px 35px 0 rgba(0, 0, 0, 0.05);
            transform: translateY(0px);
            transition: transform $t-duration, height $t-duration;

            @media (min-width: $s-docked-sidebar) {
                position: relative;
                z-index: 1;
                width: 390px;
                max-width: 90vw;
                height: 100%;
                transform: translateY(0px);
                box-shadow: none;
                border-radius: 0;
            }

            &:before {
                content: '';
                position: absolute;
                max-width: 100px;
                height: 4px;
                top: 10px;
                background-color: $c-border;
                border-radius: 100px;
                transform: translateX(-50%);
                left: 50%;
                width: 100%;

                @media (min-width: $s-docked-sidebar) {
                    opacity: 0 !important;
                }
            }

            .help-sidebar-content {
                max-height: 100%;
                padding: 30px 30px;

                @media (min-width: $s-docked-sidebar) {
                    padding: 40px 40px;
                }
            }

            .sidebar-content-blocks {
                display: grid;
                grid-template-areas: 'first button'
                                     'content content';
                grid-template-columns: 1fr auto;
                grid-template-rows: auto 1fr;
                column-gap: 20px;
                row-gap: 20px;

                button#help-toggle {
                    grid-area: button;
                    justify-self: flex-end;
                    align-self: center;

                    @media (min-width: $s-docked-sidebar) {
                        display: none;
                    }

                    span.closed {
                        display: none;
                    }
                    span.open {
                        display: block;
                    }
                }

                .content-blocks-first {
                    grid-area: first;
                }

                .content-blocks-others {
                    grid-area: content;
                }

                .content-block {

                    &:not(:first-of-type) {
                        padding-top: 40px;
                    }

                    &.title-content,
                    &.text-content {

                        & + .content-block.text-content {
                            padding-top: 20px;
                        }
                    }
                }
            }

            &.closed {

                @media (max-width: $s-docked-sidebar - 1px) {
                    height: $closedHeight;
                    transform: translateY(calc(100% + -#{$closedHeight}));

                    .help-sidebar-content {
                        overflow: hidden;
                    }

                    &:before {
                        opacity: 0;
                    }

                    button#help-toggle {
                        span.closed {
                            display: block;
                        }
                        span.opened {
                            display: none;
                        }
                    }

                    .sidebar-content-blocks {

                        .content-blocks-first {
                            min-height: 45px;
                            @include multilineEllipsis(2);
                        }

                        .content-blocks-others {
                            opacity: 0;
                        }
                    }
                }
            }

            &:not(.closed) {

                @media (max-width: $s-docked-sidebar - 1px) {
                    //height: $openedHeight;
                    //transform: translateY(calc(100% + -#{$openedHeight}));
                    height: auto;
                    max-height: $openedHeight;
                    overflow-y: auto;

                    .help-sidebar-content {
                        overflow-y: auto;
                    }

                    &:before {
                        opacity: 1;
                    }

                    button#help-toggle {
                        span.closed {
                            display: none;
                        }

                        span.opened {
                            display: block;
                        }
                    }

                    .sidebar-content-blocks {

                        .content-blocks-first {
                            align-items: flex-start;
                        }

                        .content-blocks-others {
                            transition: opacity $t-duration;
                        }
                    }
                }
            }
        }

        .config-sidebar {
            position: fixed;
            z-index: 201; // over header and overlay
            top: 0;
            right: 0;
            width: 390px;
            max-width: 90vw;
            height: 100%;
            transform: translateX(120%);
            background-color: $c-light;

            &.transition {
                transition: {
                    property: transform;
                    duration: 2 * $t-duration;
                    timing-function: ease-out;
                }
            }

            &.expanded {
                transform: translateX(0);
                //transition-duration: 3 * $t-duration;
            }

            .help-button {
                position: absolute;
                top: -10px;
                left: -31px;
                width: 31px;
                height: 102px;
                background: {
                    image: url('../../images/curved-tab-small.svg');
                    repeat: no-repeat;
                    position: left;
                    size: contain;
                    color: transparent;
                }

                @media (min-width: $s-tablet) {
                    top: 22.5px;
                }

                .button {
                    position: absolute;
                    top: calc(50% - 25px);
                    left: calc(100% - 25px);
                }
            }

            .sidebar-content {
                display: none;
                max-height: 100%;
                padding: 30px 30px;
                overflow-y: auto;

                @media (min-width: $s-tablet) {
                    padding: 40px 40px;
                }

                & > div {
                    display: none;
                }

                &.sidebar-catchphrases {

                    .catchphrases-list {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        margin: 20px 0 0;
                        padding: 0;
                        list-style: none;

                        li {

                            .button {
                                align-items: flex-start;
                                justify-content: flex-start;
                                width: 100%;
                                padding: 20px;
                                font: {
                                    family: $f-text;
                                    size: 14px;
                                    weight: normal;
                                }
                                color: $c-text;
                                text-align: left;
                                background: $bg-white;
                            }
                        }
                    }
                }

                &.sidebar-save {

                    form,
                    .form {

                        .form-group {
                            padding: {
                                left: 0;
                                right: 0;
                            }
                        }

                        .form-control:not([readonly="readonly"]) {
                            background-color: $c-light;
                        }

                        .submit-rows {
                            width: 100%;
                            margin: 10px 0 30px;

                            .submit-row {
                                margin-top: 0;
                                padding-bottom: 0;

                                //&:first-of-type {
                                //    padding-bottom: 10px;
                                //}
                            }

                            .button {
                                width: 100%;
                            }
                        }

                        .mention {
                            font-size: 12px;
                            text-align: left;
                            opacity: 1;
                        }

                        .show-on-success {
                            padding: 0;
                            text-align: left;
                            color: $c-text;
                        }
                    }
                }
            }
        }

        $avatar-position-breakpoint: $s-mobile;
        .bottom-avatar {
            position: fixed;
            z-index: -1;
            right: 0;
            bottom: 0;
            padding-right: 20px;
            padding-bottom: 0;

            @media (min-width: $avatar-position-breakpoint) {
                bottom: 0;
                right: 0;
            }

            &.with-sidebar {
                bottom: 100px;

                @media (min-width: $avatar-position-breakpoint) {
                    bottom: 0;
                    right: 390px;
                }
            }

            .tooltips {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                position: absolute;
                bottom: 80px;
                right: 0;
                width: 100%;
                gap: 10px;
                padding: {
                    right: 120px;
                    bottom: 40px;
                }

                @media (min-width: $s-tablet) {
                    position: fixed;
                    //bottom: 300px;
                    bottom: 0;
                    //right: 20px;
                    right: 0;
                    //padding: 0;
                    padding-right: 140px;
                }

                @media (min-width: $s-tablet) {
                    gap: 15px;
                }

                @media (min-width: $avatar-position-breakpoint) {
                    position: absolute;
                    bottom: calc(100% + 20px);
                    right: 40px;
                    width: 250px;
                    padding: 0;
                }

                .tooltip {
                    max-width: 90%;
                    padding: 10px;
                    font-size: 14px;
                    text-align: center;
                    background-color: $bg-white;
                    border-radius: 15px 15px 0 15px;

                    @media (min-width: $s-mobile-md) {
                        max-width: 250px;
                    }

                    @media (min-width: $s-tablet) {
                        max-width: 210px;
                        padding: 20px;
                    }

                    @media (min-width: $avatar-position-breakpoint) {
                        max-width: 250px;
                    }
                }
            }

            .picture {
                position: static;
                height: 150px;

                @media (min-width: $s-tablet) {
                    height: 200px;
                }

                @media (min-width: $avatar-position-breakpoint) {
                    height: 250px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: bottom right;
                }
            }
        }

        .bottom-progress-bar-container {
            position: fixed;
            z-index: 2;
            top: $header-height-min;
            left: 0;
            width: 100%;

            @media (min-width: $s-nav-burger-limit) {
                top: $header-height-max;
            }

            .bottom-progress-bar {
                height: 4px;
                background-color: $bg-white;
                overflow: hidden;

                & > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: max-content;
                    width: 100%;
                    height: 100%;
                    padding: 0 5px;
                    font-weight: bold;
                    color: $c-white;
                    background-color: $c-primary;
                    width: var(--progress-width);
                    font-size: 0px;

                    &::after {
                        width: var(--progress-width);
                    }
                }
            }
        }

        .config-step-content {
            position: relative;
            z-index: 1;
            width: 100%;
            margin: 0 auto;
            padding: 0 $site-width-pad-x;
            padding-top: 25px;
            min-height: calc(100vh - #{$header-height-min});

            @media (min-width: $s-mobile) {
                padding-top: 40px;
            }
            @media (min-width: $s-nav-burger-limit) {
                padding-bottom: 50px;
                min-height: calc(100vh - #{$header-height-max});
            }

            .step-head {
                max-width: 600px;

                .step-picture {
                    margin-bottom: 25px;

                    @media (min-width: $s-tablet) {
                        margin-bottom: 50px;
                    }

                    img {
                        max-height: 200px;
                    }
                }

                .step-label {
                    margin-bottom: 0;
                }

                .step-description {
                    margin-top: 10px;
                    text-align: center;
                }
            }

            ul.answers {
                width: max-content;
            }

            .small-form,
            .medium-form,
            .large-form {
                margin: 0 auto;

                & + .answers {
                    margin-top: 30px;
                }
            }

            .small-form {

                @media (min-width: $s-mobile-sm) {
                    max-width: 350px;
                }
            }

            .medium-form {

                @media (min-width: $s-mobile-sm) {
                    max-width: 500px;
                }
            }

            .large-form {

                @media (min-width: $s-mobile-sm) {
                    max-width: 800px;
                }

                form,
                .form {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    @media (min-width: $s-mobile-md) {
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        justify-content: flex-start;
                    }

                    .col {
                        padding: 0;

                        @media (min-width: $s-mobile-md) {
                            width: calc(50% - 10px);
                        }

                        label,
                        .form-control,
                        .select2-container {
                            width: 100%;
                        }
                    }

                    .full {
                        padding: 0;

                        @media (min-width: $s-mobile-md) {
                            width: 100%;
                        }
                    }

                    .submit-row {
                        margin-top: 0;
                    }
                }
            }

            form,
            .form {

                &.required-labels {
                    label.required {
                        &::after {
                            content: '(*)';
                        }
                    }
                }
            }

            .mandatory-mention {
                font: {
                    family: $f-primary;
                    weight: bold;
                    size: 12px;
                }
                text-transform: uppercase;
            }
        }
    }

    a.arrow,
    span.arrow,
    button.arrow,
    input.arrow {

        & > [class*='icon-arrow'] {
            margin-left: 20px;
        }
    }

    .step-label {
        text-align: center;
    }

    .select2-search--dropdown {
        display: none;
    }

    ul.establishments,
    ul.dates {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        margin: 0 auto 10px;
        padding: 0;
        list-style: none;

        &.establishments {
            max-width: 2 * 275px + 30px;

            @media (min-width: $s-mobile) {
                gap: 30px;
            }

            & > li {
                max-width: 275px;

                @media (min-width: $s-tablet) {
                    width: calc((100% - 40px) / 2);
                }
            }
        }

        &.dates {
            max-width: 700px;

            & > li {
                max-width: 215px;

                @media (min-width: $s-tablet) {
                    width: calc((100% - 40px) / 3);
                }
            }
        }

        & > li {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 20px;
            background-color: $bg-white;
            border: 1px solid $c-border;

            @media (min-width: $s-mobile) {
                padding: 30px;
            }

            .establishment-infos {
                width: 100%;
                text-align: left;

                .name {
                    font-weight: bold;
                    margin-bottom: 10px;
                }
            }

            .date {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 5px;

                .on {
                    font: {
                        size: 12px;
                        weight: 300;
                    }
                    text-transform: uppercase;
                }

                .day-month {
                    font: {
                        size: 20px;
                        weight: bold;
                    }
                }
            }

            a.button {
                margin-top: 20px;
            }

            .slots {
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin-top: 20px;

                .form-group {
                    padding: 0;

                    &.checked {

                        label {
                            background-color: transparentize($c-primary, 0.85);
                        }
                    }

                    &.disabled,
                    &.disabled:hover {

                        label {
                            cursor: default;

                            i[class^=icon-] {
                                color: $c-gray;
                            }
                        }
                    }

                    label {
                        justify-content: flex-start;
                        width: 100%;
                        padding: 5px 20px;
                        font: {
                            size: 14px;
                            weight: normal;
                        }
                        text-transform: none;
                        cursor: pointer;
                    }
                }
            }
        }

        & + .answers {
            margin-top: 30px;
        }
    }

    .products-list-head {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;

        @media (min-width: $s-mobile) {
            flex-direction: row-reverse;
            justify-content: space-between;
            margin-bottom: 30px;
        }

        ul.answers {
            flex-direction: row;
            //display: flex;
            gap: 10px;
            margin: 0;
            //
            //li:not(:first-of-type) {
            //    margin: 0;
            //}
        }

        .products-nb {
            font: {
                size: 16px;
                weight: bold;
            }
        }
    }

    .products-list {

        ul.products {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 20px;
            margin: 0 0 20px;
            padding: 0;
            list-style: none;

            @media (min-width: $s-mobile) {
                gap: 30px;
            }

            & > li {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                width: 100%;
                padding: 20px;
                background-color: $bg-white;
                border-radius: 15px;
                box-shadow: $shadowLarge;
                transition: box-shadow $t-duration;

                @media (min-width: $s-mobile-xs) {
                    flex-direction: row;
                    align-items: center;
                    gap: 20px;
                }

                @media (min-width: $s-mobile) {
                    width: calc((100% - 30px) / 2);
                    gap: 30px;
                }

                @media (min-width: $s-tablet) {
                }

                .no-touchevents &:hover {
                    box-shadow: $shadowShort;
                }

                .picture {
                    flex: none;
                    width: 100px;
                    height: 100px;
                    border: {
                        style: solid;
                        width: 1px;
                        radius: 15px;
                        color: transparentize($c-dark, 0.95);
                    }
                    overflow: hidden;

                    @media (min-width: $s-tablet) {
                        width: 142px;
                        height: 142px;
                    }

                    &.pdf-picture {
                        display: none;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .product-infos {
                    flex-grow: 1;
                    text-align: left;

                    .name {
                        font: {
                            size: 16px;
                            weight: bold;
                        }
                        margin-bottom: 5px;
                    }

                    .material {
                        margin-bottom: 0;
                        font: {
                            size: 12px;
                            weight: 300;
                        }
                        text-transform: uppercase;
                    }

                    .button {
                        margin-top: 20px;

                        @media (min-width: $s-mobile) {
                            margin-top: 30px;
                        }
                    }
                }

                .price {
                    flex-shrink: 0;
                    margin-bottom: 0;
                }
            }
        }
    }

    .product-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        //@media (min-width: $s-mobile) {
        //    flex-direction: row;
        //    flex-wrap: wrap;
        //    align-items: flex-start;
        //    justify-content: center;
        //}

        @media (min-width: $s-tablet) {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            column-gap: 50px;
        }

        @media (min-width: $s-desktop) {
            column-gap: 100px;
        }

        .picture {
            background-color: $bg-white;
            border: 1px solid $c-border;

            a {
                .no-touchevents &:hover {
                    img {
                        opacity: 0.5;
                    }
                }

                img {
                    transition: opacity $t-duration;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .main-picture {
            width: 100%;
            max-width: 400px;
            height: 300px;

            @media (min-width: $s-mobile) {
                height: 400px;
            }

            @media (min-width: $s-tablet) {
                order: 1;
                width: 400px;
            }
        }

        .other-pictures {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            width: 100%;
            max-width: 400px;

            //@media (min-width: $s-mobile) {
            //    flex-direction: column;
            //    max-width: calc(100% - 420px);
            //    max-height: 400px;
            //}

            @media (min-width: $s-tablet) {
                order: 3;
                flex-direction: row;
                gap: 20px;
                width: 400px;
            }

            .picture {
                max-width: calc((100% - 30px) / 4);

                //@media (min-width: $s-mobile) {
                //    max-width: 100%;
                //    max-height: calc((100% - 30px) / 4);
                //}

                @media (min-width: $s-tablet) {
                    max-width: calc((100% - 80px) / 5);
                }
            }
        }

        .infos {
            width: 100%;

            @media (min-width: $s-tablet) {
                order: 2;
                flex-grow: 1;
                max-width: calc(100% - 450px);
            }

            @media (min-width: $s-desktop) {
                max-width: calc(100% - 500px);
            }

            .name {
                margin-bottom: 10px;
            }

            .material {
                margin-bottom: 0;
                font: {
                    size: 16px;
                    weight: 300;
                }
                text-transform: uppercase;
            }

            .tags {
                display: flex;
                flex-wrap: wrap;
                gap: 15px;
                margin: 10px 0 0;
                list-style: none;

                @media (min-width: $s-mobile) {
                    margin-top: 20px;
                }

                li {
                    padding: 5px 10px;
                    font: {
                        size: 13px;
                        weight: bold;
                    }
                    background-color: $c-second;
                    border-radius: 25px;

                    @media (min-width: $s-mobile) {
                        padding: 10px 15px;
                        font-size: 14px;
                    }
                }
            }

            .description {
                margin-top: 20px;
            }

            a.button {
                margin-top: 20px;
            }
        }
    }

    .other-products {
        margin-top: $section-pad-y-min;

        @media (min-width: $s-mobile) {
            margin-top: $section-pad-y-max;
        }

        .scroll-indicator {
            @include scroll-indicator(150px, 0);
            display: none;
            width: max-content;
            margin: 0 auto 20px;

            @media (min-width: $s-mobile) {
                display: block;
                margin-bottom: 30px;
            }

            .scroll-down a {
                align-items: center;
                padding-top: 25px;
            }
        }

        .subtitle {
            text-align: center;
        }
    }

    .inquiry-place-form,
    .inquiry-place-form .custom-place,
    .inquiry-religion-form,
    .inquiry-death-notice-form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .form-group {
            padding: 0;
        }

        .submit-row {
            margin-top: 0;
        }
    }

    .establishment-address {
        font-weight: bold;
    }

    .form-group:empty {
        display: none;
    }

    .form-group + .submit-row,
    .submit-row + .submit-row {
        margin-top: 0;
    }

    .submit-row {
        margin-top: 20px;
        padding: 0;
        text-align: center;
    }

    .submit-rows {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: max-content;
        margin: 0 auto;

        .submit-row {
            button {
                width: 100%;
            }
        }
    }

    .inquiry-death-notice-form {

        .catchphrase-row {

            .catchphrase-field {

                textarea {
                    padding: 0;
                    background: none;
                    box-shadow: none;
                    border: none;
                }

                button {
                    width: 100%;
                }

                //position: relative;
                //
                //textarea {
                //    padding-bottom: 60px;
                //}
                //
                //button {
                //    position: absolute;
                //    bottom: $form-field-pad-h + $form-field-border-width;
                //    left: $form-field-pad-w;
                //    right: $form-field-pad-w;
                //}
            }
        }

        textarea {
            resize: none;
        }
    }

    .death-notice-preview {
        max-width: 290px;
        margin: 0 auto 20px;
        padding: 20px;
        background-color: $bg-white;
        border: 1px solid $c-dark;

        @media (min-width: $s-mobile) {
            margin-bottom: 30px;
            padding: 30px;
        }

        .cities {
            font-weight: bold;
        }

        .deceased {
            font-weight: bold;
            text-align: center;
        }
    }

    &.summary {

        main {
            //background: none;
            background: $bg-light url('../../images/pattern_light.jpg');

            section.banner {
                display: flex;
                flex-direction: column;
                padding-bottom: 0;
            }
        }

        .column-left {
            margin-left: auto;
            margin-right: auto;
            padding-left: 20px;
            padding-right: 20px;
            max-width: $site-width;

            @media (min-width: ($columnsBreakpoint)) {
                padding-left: $site-width-pad-x;
                padding-right: $site-width-pad-x;
            }

            @media (min-width: $site-width) {
                padding-left: 100px;
                padding-right: 100px;
            }


            //.config-step-content {
            //    padding: 0 0 50px;
            //
            //    .step-label,
            //    .step-description {
            //        text-align: left;
            //    }
            //}

            .step-head {
                margin-top: 30px;
                margin-bottom: 20px;
                text-align: left;

                @media (min-width: ($columnsBreakpoint)) {
                    margin-top: 40px;
                }

                .step-label {
                    text-align: left;
                }
            }

            .button-anchor {
                display: flex;
                margin-bottom: 20px;
                margin-left: auto;
                margin-right: auto;
                width: 100%;

                @media (min-width: $columnsBreakpoint) {
                    display: none;
                }
            }

            .summary-data {
                margin-bottom: 20px;

                &:last-of-type {

                    @media (min-width: $columnsBreakpoint) {
                        margin-bottom: 40px;
                    }
                }

                .summary-data-content {
                    background-color: $c-white;
                    padding: 20px 20px;
                    font-size: 14px;

                    @media (min-width: $columnsBreakpoint) {
                        padding: 24px 30px;
                    }

                    .title-and-button {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 10px;
                        margin-bottom: 20px;

                        .title {
                            margin-bottom: 0;
                        }

                        .button {
                            flex-shrink: 0;
                            padding: 12px 14px;

                            i {
                                margin-right: 0;
                            }

                            span {
                                display: none;
                            }

                            @media (min-width: $s-mobile) {
                                padding: 12px 20px;

                                i {
                                    margin-right: 10px;
                                }
                                span {
                                    display: block;
                                }
                            }
                        }
                    }

                    .subtitle,
                    .mention {
                        margin-top: 20px;
                    }

                    .fields {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;

                        @media (min-width: $s-mobile-md) {
                            flex-direction: row;
                            flex-wrap: wrap;
                        }

                        .field {

                            @media (min-width: $s-mobile-md) {
                                width: calc(50% - 10px);
                            }

                            label {
                                margin-bottom: 5px;
                                font-size: 10px;
                            }

                            .value {
                                font-weight: bold;
                                padding: 14px 14px;
                                background-color: $c-gray-light;
                                border-radius: $form-field-border-radius;

                            }
                        }
                    }

                    .products-list {

                        ul.products {
                            margin-bottom: 0;
                            gap: 10px;

                            & > li {
                                width: 100%;
                                padding: 10px;
                                border-radius: 10px;
                                box-shadow: none;

                                @media (min-width: $s-mobile) {
                                    gap: 15px;
                                }

                                .no-touchevents &:hover {
                                    box-shadow: none;
                                }

                                @media (min-width: $s-mobile) {
                                    padding-right: 30px;
                                }

                                .picture {
                                    width: 50px;
                                    height: 50px;
                                    padding: 0;
                                    border-radius: 10px;
                                }

                                .product-infos {
                                    flex-grow: 1;
                                    text-align: left;

                                    .name {
                                        font-size: 14px;
                                    }

                                    .material {
                                        font-size: 10px;
                                    }
                                }

                                .price {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }

            .establishment {

                .establishment-content {

                    .establishment-content-wrapper {
                        display: flex;
                        gap: 20px;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;

                        @media (min-width: $s-mobile-xs) {
                            flex-direction: row;
                            text-align: left;
                        }

                        .establishment-picture {

                        }

                        .establishment-infos {

                            .establishment-name {
                                margin-bottom: 6px;

                            }

                            .establishment-address {
                                font-weight: normal;

                            }
                        }
                    }
                }
            }

            .prices {

                order: 1;

                @media (min-width: $columnsBreakpoint) {
                    order: initial;
                }

                .prices-content {

                    //@media (min-width: $s-mobile-md) {
                    //    display: flex;
                    //    flex-direction: row;
                    //    align-items: center;
                    //    gap: 20px;
                    //}

                    //@media (min-width: $s-tablet) {
                    //    gap: 40px;
                    //}

                    .button-anchor {
                        margin-top: 20px;
                        margin-bottom: 0;
                    }

                    .picture {
                        display: none;

                        @media (min-width: $s-mobile-md) {
                            display: block;
                            flex-shrink: 0;
                            width: 90px;
                        }
                    }

                    .price-row {
                        display: flex;
                        gap: 10px;
                        align-content: flex-end;
                        justify-content: space-between;

                        &:not(:first-child) {
                            margin-top: 5px;
                        }

                        .price-label {
                            margin-bottom: 0;
                        }

                        .price {
                            flex-shrink: 0;
                            margin-bottom: 0;
                        }
                    }

                    .mention {
                        margin-top: 10px;
                        font-size: 12px;
                    }
                }
            }

            .disclaimer {
                order: 2;

                @media (min-width: $columnsBreakpoint) {
                    order: initial;
                }

                .disclaimer-content {
                    font-size: 16px;
                    font-weight: bold;
                }
            }

            .death-notice {

                .summary-data-content {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    @media (min-width: $s-mobile-md) {
                        flex-direction: row;
                        flex-wrap: wrap;
                    }

                    .title-and-button,
                    .mention {
                        margin: 0;

                        @media (min-width: $s-mobile-md) {
                            width: 100%;
                        }
                    }

                    .infos,
                    .preview {

                        @media (min-width: $s-mobile-md) {
                            flex: 1;
                        }

                        .death-notice-preview {
                            margin-bottom: 0;
                        }
                    }

                    .fields {

                        @media (min-width: $s-mobile-md) {
                            flex-direction: column;
                        }

                        .field {

                            @media (min-width: $s-mobile-md) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .column-right {
            background-color: $c-white;
            margin-left: 0px;

            @media (min-width: ($columnsBreakpoint)) {
                width: 480px;
                //margin-left: 0;
            }

            @media (min-width: $site-width-no-padding) {
                //margin-left: calc(100px - 2 * $site-width-pad-x);
                //width: 480px;
                //margin-left: 100px;
            }

            //.sticky {
            //
            //    @media (min-width: ($columnsBreakpoint)) {
            //        position: sticky;
            //        top: $header-height-max;
            //        padding-top: $section-pad-y-max;
            //    }
            //}

            .sticky-wrapper  {
                @media (min-width: ($columnsBreakpoint)) {
                    padding-top: 40px;
                }
            }

            .column-right-content {
                position: relative;
                padding: 30px 20px;

                @media (min-width: $columnsBreakpoint) {
                    padding: 0 $site-width-pad-x;
                }

                //@media (min-width: ($columnsBreakpoint)) {
                //    min-height: calc(100vh - (#{$header-height-max} + 2 * 40px)); // 40px = banner padding-top
                //}

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: {
                        top: $site-width-pad-x;
                        bottom: $site-width-pad-x;
                    }
                }

                .title,
                .subtitle {
                    //text-align: center;
                }

                .title {
                    margin-bottom: 10px;
                }

                .form {
                    .col {
                        margin-bottom: 0;
                    }
                    .form-group {
                        padding: 0 0 15px;
                    }

                    label.required {
                        &::after {
                            content: '(*)';
                        }
                    }
                }



                .comment-row {

                    //label {
                    //    margin-bottom: 15px;
                    //    font: {
                    //        size: $form-field-text-font-s;
                    //        weight: normal;
                    //    }
                    //    line-height: 1;
                    //    text-transform: none;
                    //}

                    textarea.form-control {
                        max-height: 140px;
                        line-height: 17px;
                        resize: none;
                    }
                }

                .mention {
                    font-size: 12px;
                    line-height: 1.4;
                    text-align: left;
                }

                .checkbox {

                    label {
                        align-items: flex-start;
                        font: {
                            size: 12px;
                            weight: 500;
                        }
                        line-height: 1.4;
                        text-transform: none;
                    }
                }

                .submit-row {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 10px;
                    margin-top: 0;
                    padding-bottom: 0;
                    //text-align: center;

                    //@media (min-width: $columnsBreakpoint) {
                    //    flex-wrap: nowrap;
                    //}
                }
            }
        }

        .inquiry-send-loading {
            position: fixed;
            z-index: 201;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .overlay {
                background-color: transparentize($c-dark-bg, 0.6);
                pointer-events: none;
            }

            .content {
                position: relative;
                z-index: 1;
                max-width: 500px;
                padding: 25px 30px;
                text-align: center;
                background-color: $bg-white;

                @media (min-width: $s-mobile) {
                    padding: 50px 60px;
                }

                .picture {
                    height: 215px;
                    margin-bottom: 30px;
                }

                .progress-bar {
                    max-width: 200px;
                    margin: 0 auto;
                    border: {
                        style: solid;
                        width: 3px;
                        color: #f0f0f3;
                        radius: 20px;
                    }
                    box-shadow: 1.8px 1.8px 3.6px 0 rgba(174, 174, 192, 0.2), -1.8px -1.8px 3.6px 0 #fff;
                    background-color: #f0f0f3;

                    & > div {
                        height: 15px;
                        border-radius: 20px;
                        box-shadow:
                                inset 1.6px 1.6px 3.3px 0 transparentize(#AEAEC0, 0.77),
                                inset -1.6px -1.6px 1.6px 0 transparentize($c-white, 0.3);
                        background-color: $c-light;

                        & > div {
                            width: 15px;
                            height: 15px;
                            border-radius: 12px;
                            background-image: linear-gradient(332deg, $c-primary 107%, $c-second -5%);
                        }
                    }
                }
            }
        }
    }

    &.confirm {

        .confirm-content {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 50px;

            @media (min-width: $s-tablet) {
                flex-direction: row;
                align-items: flex-start;
                justify-content: center;
                gap: 30px;
            }

            & > div {
                padding: 20px 25px;
                background-color: $bg-white;

                @media (min-width: $s-tablet) {
                    flex: 1;
                    max-width: calc(50% - 15px);
                    padding: 40px 50px;
                }
            }

            .reference {
                text-align: center;
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin: {
                    top: 20px;
                    bottom: 0;
                }
                padding: 20px 30px;

                @media (min-width: $s-mobile-sm) {
                    flex-direction: row;
                    justify-content: space-between;
                }
            }

            .button {
                width: 100%;
                margin-top: 20px;
            }

            .type {
                padding: 20px;
                text-align: center;
            }

            .phone {
                padding: 20px;
                text-align: center;
            }
        }
    }

    &.review {

        .review-content {

            .reviews-rating {
                background-color: transparent;
                margin: 0;
                padding: 0;

                @media (min-width: $s-mobile) {
                    display: flex;
                    align-items: center;
                }

                .rating-stars {

                    &:hover {

                        .rating-star i.icon-star-full {
                            opacity: 1;
                        }
                    }

                    .rating-star {
                        cursor: pointer;

                        &:hover {

                            i.icon-star-full {
                                opacity: 1;
                            }

                            & ~ .rating-star {

                                i.icon-star-full {
                                    opacity: 0.25;
                                }
                            }
                        }

                        i.icon-star-full {
                            width: auto;
                            padding: 0 5px;
                            font-size: 2em;
                        }
                    }
                }
            }

            .mention {
                font-size: 12px;
                text-align: left;
                opacity: 1;
            }

            .show-on-success {
                padding: 0;
                text-align: left;
                color: $c-text;
            }
        }
    }
}